import { Component, input } from '@angular/core';
import { FaqItemComponent } from '@ui/faq/faq-item/faq-item.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FaqListComponent } from '@ui/faq/faq-list/faq-list.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FaqList } from '@data-content/faq';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';
import { FaqSection } from '@data-content/core-components';

@Component({
  selector: 'nav-help-faq',
  standalone: true,
  templateUrl: './help-faq.component.html',
  imports: [
    FaqItemComponent,
    CdkAccordionModule,
    FaqListComponent,
    AsyncPipe,
    NgIf,
    RouterLink,
    CustomIconsModule
  ],
})
export class HelpFaqComponent {
  data = input.required<FaqSection | undefined>();
  faqItems = input.required<FaqList | null>();
}
