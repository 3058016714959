import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgClass, NgIf } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { Menu } from '@data-content/menu';
import { RouterLink } from '@angular/router';
import { HelpSlideInComponent } from "../help-slide-in/help-slide-in.component";
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';
import { NavbarStore } from '../data/navbar.store';

@Component({
  selector: 'nav-topbar',
  standalone: true,
  imports: [
    CdkAccordionModule,
    OverlayModule,
    CdkMenuModule,
    NgClass,
    RouterLink,
    HelpSlideInComponent,
    NgIf,
    CustomIconsModule
  ],
  templateUrl: './topbar.component.html',
})
export class TopbarComponent {
  readonly store = inject(NavbarStore);

  data = input.required<Menu>();
  toggleSidebar = output<void>();
}
