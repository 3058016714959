import { bootstrapApplication } from '@angular/platform-browser';
import { appConfigFactory } from './app/app.config';
import { AppComponent } from './app/app.component';
import {RuntimeConfig} from "./environments/runtimeConfig";
import {environment} from "./environments/environment";

const applyRuntimeConfig = async function () : Promise<boolean> {
  let response = await fetch('runtime-config.json');

  if(response.ok) {
    let config = (await response.json()) as RuntimeConfig;
    Object.assign(environment, config);
    return true;
  }
  else {
    console.error('Cannot fetch runtime-config.json')
    return false;
  }
};

applyRuntimeConfig().then(() => {
  bootstrapApplication(AppComponent, appConfigFactory())
    .catch((err) => console.error(err));
});
