import { Component, input } from '@angular/core';
import { Support } from '@data-content/support';
import { BackgroundImageDirective } from '@ui/background-image.directive';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';

@Component({
  selector: 'nav-help-support',
  standalone: true,
  imports: [BackgroundImageDirective, CustomIconsModule],
  templateUrl: './help-support.component.html',
})
export class HelpSupportComponent {
  data = input.required<Support | null>();
}
