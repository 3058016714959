<ng-container *ngIf="data() as data">
  <nav class="bg-white">
    <nav-topbar (toggleSidebar)="toggleSidebar()" [data]="data"></nav-topbar>
    <nav-sidebar
      (toggleSidebar)="toggleSidebar()"
      [isOpen]="isSidebarOpen()"
      [data]="data"
    ></nav-sidebar>
  </nav>
</ng-container>
