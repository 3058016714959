<div
  class="fixed inset-0 z-50 h-full w-full"
  [ngClass]="{ invisible: !isOpen() }"
>
  <div
    class="absolute inset-0 z-50 h-full w-full bg-gray-900 transition-all duration-200 ease-out"
    [ngClass]="{ 'opacity-0': !isOpen() }"
    (click)="toggleSidebar.emit()"
  ></div>
  <div
    class="absolute right-0 top-0 z-50 h-full w-[300px] bg-white px-2 transition-all duration-200 ease-out"
    [ngClass]="{ 'translate-x-full': !isOpen() }"
  >
    <div class="flex h-16 justify-end px-3 py-2">
      <div
        class="flex cursor-pointer flex-col justify-center"
        (click)="toggleSidebar.emit()"
      >
        <fa-icon class="fa-xl" [icon]="['far', 'xmark']"></fa-icon>
      </div>
    </div>
    <div class="gap-1px-4 flex flex-col py-2" cdkAccordion>
      @for (item of data().items.data; track $index) {
        <div cdkAccordionItem #menuItem="cdkAccordionItem">
          <div
            class="flex cursor-pointer justify-between px-3 py-2"
            (click)="menuItem.toggle()"
          >
            <div class="font-semibold">{{ item.attributes.title }}</div>
            <div class="flex flex-col justify-center">
              <div class="pb-3 pr-3">
                <fa-icon
                  class="fa-sm absolute transition-all"
                  [ngClass]="{ 'rotate-180': menuItem.expanded }"
                  [icon]="['fas', 'chevron-down']"
                ></fa-icon>
              </div>
            </div>
          </div>
          <div
            cdkAccordionItem
            [ngClass]="
              menuItem.expanded
                ? 'max-h-96 opacity-100'
                : 'invisible max-h-0 opacity-0'
            "
            class="flex cursor-pointer flex-col gap-1 px-3 py-2 font-semibold transition-all"
          >
            @for (subItem of item.attributes.children.data; track $index) {
              @if (subItem.attributes.isExternal) {
                <a
                  [href]="subItem.attributes.url"
                  [target]="subItem.attributes.target"
                  (click)="toggleSidebar.emit()"
                  class="py-2 pl-8 pr-3"
                  >{{ subItem.attributes.title }}</a
                >
              } @else {
                <a
                  [routerLink]="subItem.attributes.url"
                  (click)="toggleSidebar.emit()"
                  class="py-2 pl-8 pr-3"
                  >{{ subItem.attributes.title }}</a
                >
              }
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>
