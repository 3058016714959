import {OAuthStorage} from 'angular-oauth2-oidc';
import {Injectable} from '@angular/core';

@Injectable()
export class InMemoryAuthStorage extends OAuthStorage {
  private readonly storage: { [key: string]: string } = {};

  getItem(key: string): string {
    return this.storage[key];
  }
  removeItem(key: string) {
    delete this.storage[key];
  }
  setItem(key: string, data: string) {
    this.storage[key] = data;
  }
}
