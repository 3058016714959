import { Component, inject, input, signal } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { NgIf } from '@angular/common';
import { MenuContentService } from '@data-content/menu-content.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  imports: [SidebarComponent, TopbarComponent, NgIf],
})
export class NavbarComponent {
  data = inject(MenuContentService).menuData;
  isSidebarOpen = signal<boolean>(false);
  toggleSidebar(): void {
    this.isSidebarOpen.update((isOpen) => !isOpen);
  }
}
