import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Menu } from '@data-content/menu';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';

@Component({
  selector: 'nav-sidebar',
  standalone: true,
  imports: [CdkAccordionModule, NgClass, RouterLink, CustomIconsModule],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  isOpen = input<boolean>();
  data = input.required<Menu>();
  toggleSidebar = output<void>();
}
