import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { CmsResponse } from '@data-content/response';
import { ContentService } from '@data-content/content.service';
import qs from 'qs';
import { HelpSlideIn } from './help-slide-in';

@Injectable({
  providedIn: 'root',
})
export class HelpSlideInContentService extends ContentService {
  http = inject(HttpClient);

  private data$ = this.http
    .get<
      CmsResponse<HelpSlideIn>
    >(`${this.baseUrl}help-slidein?${qs.stringify(query)}`)
    .pipe(map(this.mapAttributes));

  data = toSignal(this.data$, {
    initialValue: null,
  });
}

const query = {
  populate: {
    faqSection: {
      populate: {
        faqItems: true,
        button: true,
      },
    },
  },
};
