<button
  type="button"
  class="rounded-lg border px-3 py-2"
  (click)="context_help_slidein.open()"
>
  <div class="flex items-center gap-1 pt-0.5">
    <fa-icon
      class="text-brand-600"
      [icon]="['far', 'message-question']"
    ></fa-icon>
    {{ data()?.buttonText }}
  </div>
</button>
<ui-slidein #context_help_slidein title="{{ data()?.title }}">
  <div class="flex flex-col gap-8">
    <nav-help-faq
      [data]="data()?.faqSection"
      [faqItems]="faqItems$ | async"
    ></nav-help-faq>

    <ui-contact />

    <nav-help-support [data]="supportData()" />
  </div>
</ui-slidein>
