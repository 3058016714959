import { ApplicationConfig, ErrorHandler, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from '@util/auth.service';
import {
  AuthConfig,
  OAuthStorage,
  provideOAuthClient,
  ValidationHandler
} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { InMemoryAuthStorage } from '@util/in-memory-auth-storage';
import { environment } from '../environments/environment';
import { authConfigFactory } from '@util/auth.config';
import { PlatformLocation } from '@angular/common';
import { PlatformService } from '@util/platform.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
// Using factory here will postpone solving environment variables until the real call of function
export const appConfigFactory = (): ApplicationConfig => {
  return {
    providers: [
      provideZoneChangeDetection({eventCoalescing: true}),
      provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({scrollPositionRestoration: "top"})),
      provideClientHydration(),
      provideHttpClient(withFetch(), withInterceptorsFromDi()),
  
      provideOAuthClient({
        resourceServer: {
          allowedUrls: [environment.horizonsApi ?? ''],
          sendAccessToken: true,
        },
      }),
      {provide: OAuthStorage, useClass: InMemoryAuthStorage},
      {provide: AuthService, useClass: AuthService},
      {provide: ValidationHandler, useClass: JwksValidationHandler},
      {
        provide: AuthConfig,
        useFactory: authConfigFactory,
        deps: [PlatformLocation, PlatformService],
      },
      { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
    ],
  };
}