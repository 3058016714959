import { Component, effect, inject, input } from '@angular/core';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';
import { SlideinComponent } from '@ui/slidein/slidein.component';
import { RouterLink } from '@angular/router';
import { FaqContentService } from '@data-content/faq-content.service';
import { HelpFaqComponent } from './components/faq/help-faq.component';
import { AsyncPipe } from '@angular/common';
import { FaqItemType } from '@data-content/faq';
import { HelpSlideInContentService } from './data/help-slide-in-content.service';
import { SupportContentService } from '@data-content/support-content.service';
import { HelpSupportComponent } from './components/support/help-support.component';
import { ContactComponent } from '@ui/contact/contact.component';

@Component({
  selector: 'nav-help-slide-in',
  standalone: true,
  imports: [
    SlideinComponent,
    HelpFaqComponent,
    HelpSupportComponent,
    RouterLink,
    AsyncPipe,
    CustomIconsModule,
    ContactComponent
],
  templateUrl: './help-slide-in.component.html',
})
export class HelpSlideInComponent {
  faqTag = input.required<string>();

  data = inject(HelpSlideInContentService).data;
  supportData = inject(SupportContentService).data;

  faqContentService = inject(FaqContentService);
  faqItems$ = this.faqContentService.faqItems$({ tag: 'landing' });

  constructor() {
    effect(() => {
      this.faqItems$ = this.faqContentService.faqItems$({ tag: this.faqTag() as FaqItemType });
    });
  }
}