import { AuthConfig } from 'angular-oauth2-oidc';
import { PlatformLocation } from '@angular/common';
import { environment } from '../../environments/environment';
import { RuntimeConfig } from '../../environments/runtimeConfig';
import { PlatformService } from '@util/platform.service';

const backOfficeParam = 'backofficeLink';

export function authConfigFactory(
  platformLocation: PlatformLocation,
  platformService: PlatformService,
): AuthConfig {
  const identityUri = getIdentityUri(environment, platformLocation);

  return getAuthConfig(identityUri, platformService);
};

const getIdentityUri = (
  environment: RuntimeConfig,
  platformLocation: PlatformLocation,
): string | undefined => {
  if (!platformLocation) {
    return environment.identityUri;
  }

  if (new URLSearchParams(platformLocation.search).has(backOfficeParam)) {
    return environment.backofficeIdentityUri;
  }

  return environment.identityUri;
}

const getAuthConfig = (
  identityUri: string | undefined,
  platformService: PlatformService
): AuthConfig => {
  let baseHref = '/';
  if (platformService.isBrowser$.value) {
    const bases = document.getElementsByTagName('base');
    if (bases.length > 0) {
      baseHref = bases[0].href;
    }
  }

  return {
    issuer: identityUri,
    logoutUrl: identityUri + '/connect/endsession',
    redirectUri: baseHref,
    silentRefreshRedirectUri: baseHref + 'silent-refresh.html',
    clientId: 'ichoosr_backoffice_app',
    scope: 'openid profile ichoosr_api',
    sessionChecksEnabled: true,
  };
};
