<section class="rounded-md bg-gray-50" *ngIf="data() as faqData">
  <div class="mx-auto max-w-screen-xl px-4 py-12 md:px-8">
    <div class="mb-12 flex flex-col gap-4 px-4">
      <h4 class="text-center">{{ faqData.title }}</h4>
      <p class="text-center text-lg text-gray-600">
        {{ faqData.text }}
      </p>
      <a
        class="flex justify-center gap-2 text-brand-700"
        [routerLink]="faqData.button.url"
      >
        <span class="font-semibold">{{ faqData.button.text }}</span>
        <div class="pt-0.5">
          <fa-icon class="fa-sm" [icon]="['far', 'chevron-right']"></fa-icon>
        </div>
      </a>
    </div>
    <div class="p-1" *ngIf="faqItems() as list">
      <ui-faq-list [list]="list"></ui-faq-list>
    </div>
  </div>
</section>
