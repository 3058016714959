import { NgClass } from "@angular/common";
import { Component, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { GenericContentService } from "@data-content/generic-content.service";
import { RegistrationService } from "@data/services/registration.service";
import { RegistrationStore } from "@data/store/registration.store";
import { CustomIconsModule } from "@ui/custom-icons/custom-icons.module";
import { TextInputComponent } from "@ui/text-input/text-input.component";
import { emailInvalidValidator } from "@util/validators/email.validator";
import { ContactContent } from "app/register/data/content/contact";
import { AnalyticsService } from "@data/analytics/analytics.service";

@Component({
    selector: 'ui-contact',
    standalone: true,
    templateUrl: './contact.component.html',
    imports: [
        CustomIconsModule,
        NgClass,
        ReactiveFormsModule,
        TextInputComponent,
    ],
})
export class ContactComponent {
    public data = toSignal(inject(GenericContentService).getAllOneLevel<ContactContent>('contact'));
    public form = inject(FormBuilder).group({
        firstNameControl: new FormControl<string | null>(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
        ]),
        lastNameControl: new FormControl<string | null>(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
        ]),
        emailControl: new FormControl<string | null>(null, [
            Validators.required,
            emailInvalidValidator,
        ]),
        messageControl: new FormControl<string | null>(null, [
            Validators.required,
        ]),
    });
    private registrationStore = inject(RegistrationStore);
    private registrationService = inject(RegistrationService);
    private analyticsService = inject(AnalyticsService);

    public state: 'none' | 'sending' | 'sent' | 'error' = 'none';

    public sendMessage() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const message = {
                registrationNumber: this.registrationStore.registration()?.number ?? '',
                communityId: this.registrationStore.registration()?.communityId,
                email: this.form.controls.emailControl.value!,
                senderName: `${this.form.controls.firstNameControl.value} ${this.form.controls.lastNameControl.value}`,
                message: this.form.controls.messageControl.value!,
            };
            this.state = 'sending';
            this.form.disable();
            this.registrationService.sendContactMessage(message).subscribe({
                complete: () => {
                    this.analyticsService.pushEvent(
                      this.analyticsService.createContactFormSubmittingEvent({
                        registration_number: this.registrationStore.registration()?.number ?? ''
                      })
                    );

                    this.state = 'sent';
                    this.form.enable();
                },
                error: () => this.state = 'error',
            });
        }
    }
}
